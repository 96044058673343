import React, { FC, useContext, useState, useEffect } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { setModalHandler } from "../../context/actions/modal";
import { storeStepValues } from "../../utils/forms";
import { setStepFormValues } from "../../context/actions/formsAction";
import { step3ValidationHandler } from "./formValidations";
import { storelocalStorageData } from "../../utils/forms";
import { smoothScroll } from "../../utils/smoothScroll";
// section 1 icons
import HouseQ1 from "../../assets/icons/questionsForm/step3/Q1_window.svg";
import House_6_Off from "../../assets/icons/questionsForm/step3/off_Q1_6_.svg";
import House_6_On from "../../assets/icons/questionsForm/step3/on_Q1_6_.svg";
import House_7_Off from "../../assets/icons/questionsForm/step3/off_Q1_7_.svg";
import House_7_On from "../../assets/icons/questionsForm/step3/on_Q1_7_.svg";
import House_8_Off from "../../assets/icons/questionsForm/step3/off_Q1_8_.svg";
import House_8_On from "../../assets/icons/questionsForm/step3/on_Q1_8_.svg";
// section 2 icons
import HouseQ2 from "../../assets/icons/questionsForm/step3/Q2_window.svg";
// section 3 icons
import HouseQ3 from "../../assets/icons/questionsForm/step3/Q3_window.svg";
// section 4 icons
import HouseQ4 from "../../assets/icons/questionsForm/step3/Q4_window.svg";
// Sections icons of this page
import BlueArrow from "../../assets/icons/questionsForm/step1/arrow-down-blue.svg";
import TransparentArrow from "../../assets/icons/questionsForm/step1/arrow-down-transparency.svg";
import NextArrow from "../../assets/icons/questionsForm/step1/arrow-next.svg";
import InfoIcon from "../../assets/icons/information-button.svg";
import { ScreenSize } from "../../utils/windowDimension";

interface Step3Interface {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setShowAnimation: React.Dispatch<React.SetStateAction<string>>;
  selectedQuestion?: number;
  setSelectedQuestion?: any;
  formsRightQuestionsHeight?: any;
  formsLeftQuestionsHeight?: any;
  formHeightHandler?: any;
}

// FormOptionTypes types
type FormOptionTypes = {
  id: number;
  name: string;
  selected: string;
};

const Step3: FC<Step3Interface> = ({
  setStep,
  setShowAnimation,
  setSelectedQuestion,
  selectedQuestion,
  formsRightQuestionsHeight,
  formsLeftQuestionsHeight,
  formHeightHandler,
}) => {
  // global state
  const { state, dispatch }: any = useContext(GlobalContext);
  const browserZoomLevel: any =
    (window.outerWidth / window.document.documentElement.clientWidth) * 100;
  const screenSize: any = ScreenSize();
  // list of step 1 options
  const formOptions: FormOptionTypes[] = [
    {
      id: 1,
      name: state?.language?.language === "fr" ? "Vitrage" : "Verglasung",
      selected:
        selectedQuestion === 1 ||
        selectedQuestion === 2 ||
        selectedQuestion === 3 ||
        selectedQuestion === 4
          ? "true"
          : "false",
    },
    {
      id: 2,
      name:
        state?.language?.language === "fr"
          ? "Isolation toiture ou plafond"
          : "Dämmung Dach oder Decke",
      selected:
        selectedQuestion === 2 ||
        selectedQuestion === 3 ||
        selectedQuestion === 4
          ? "true"
          : "false",
    },
    {
      id: 3,
      name: state?.language?.language === "fr" ? "Isolation murs" : "Dämmung Mauern",
      selected:
        selectedQuestion === 3 || selectedQuestion === 4 ? "true" : "false",
    },
    {
      id: 4,
      name: state?.language?.language === "fr" ? "Isolation sol" : "Dämmung Fußboden",
      selected: selectedQuestion === 4 ? "true" : "false",
    },
  ];

  const [step3, setStep3] = useState({
    question_one: {
      question_id: 11,
      question_title: "Quel est le type de vitrage principal ? ",
      answer_id: 0,
      answer_title: "",
    },
    question_two: {
      question_id: 12,
      question_title:
        "Un isolant est-il présent dans l'épaisseur de la toiture ou du plafond ? ",
      answer_id: 0,
      answer_title: "",
    },
    question_three: {
      question_id: 13,
      question_title: "Quelle est l'épaisseur approximative de l'isolant ?",
      answer_id: 0,
      answer_title: "",
    },
    question_four: {
      question_id: 14,
      question_title:
        "Savez-vous si des primes à l’isolation ont été demandées pour ces travaux? ",
      answer_id: 0,
      answer_title: "",
    },
    question_five: {
      question_id: 37,
      question_title: "S’agit-il d’une isolation...",
      answer_id: 0,
      answer_title: "",
    },
    question_six: {
      question_id: 16,
      question_title: "Un  isolant est-il présent dans l'épaisseur des murs ? ",
      answer_id: 0,
      answer_title: "",
    },
    question_seven: {
      question_id: 17,
      question_title: "Quelle est l'épaisseur approximative de l'isolant ?",
      answer_id: 0,
      answer_title: "",
    },
    question_eight: {
      question_id: 18,
      question_title:
        "Savez-vous si des primes à l’isolation ont été demandées pour ces travaux? ",
      answer_id: 0,
      answer_title: "",
    },
    question_nine: {
      question_id: 19,
      question_title: "S’agit-il d’une isolation...",
      answer_id: 0,
      answer_title: "",
    },
    question_ten: {
      question_id: 21,
      question_title:
        "Un  isolant est-il présent dans le plancher (ou dalle) ? ",
      answer_id: 0,
      answer_title: "",
    },
    question_eleven: {
      question_id: 22,
      question_title: "Quelle est l'épaisseur approximative de l'isolant ?",
      answer_id: 0,
      answer_title: "",
    },
    question_twelve: {
      question_id: 23,
      question_title:
        "Savez-vous si des primes à l’isolation ont été demandées pour ces travaux? ",
      answer_id: 0,
      answer_title: "",
    },
    question_thirteen: {
      question_id: 24,
      question_title: "S’agit-il d’une isolation...",
      answer_id: 0,
      answer_title: "",
    },
    question_fourteen: {
      question_id: 25,
      question_title: "Avez-vous un chauffage par le sol?",
      answer_id: 0,
      answer_title: "",
    },
  });

  const [toggle, setToggle] = useState({
    doubleVitrage: false,
    // section 2
    sectioTwoCheckBoxOne: false,
    sectioTwoCheckBoxTwo: false,
    // section 3
    sectioThreeCheckBoxOne: false,
    sectioThreeCheckBoxTwo: false,
    // section 4
    sectioFourCheckBoxOne: false,
    sectioFourCheckBoxTwo: false,
  });

  // useEffect to check if data exsit in localStorage to show selectedData
  useEffect(() => {
    let localStorageData: any = localStorage.getItem("formData");
    let formData = JSON.parse(localStorageData);
    if (formData?.stepthree) {
      // to store in local and Global states
      setStep3(formData?.stepthree);
      setToggle(formData?.stepthreeToggles);
      setStepFormValues(formData?.stepthree, dispatch);
    }
    window.scrollTo(0, 0);
  }, [dispatch]);

  const toggleHandler = (key: any, value: Boolean) => {
    let duplicate: any = { ...toggle };
    duplicate[key] = value;
    setToggle(duplicate);
  };

  // handler to store values into Global state
  const submitHandler = () => {
    let validationOptions = step3ValidationHandler(step3, toggle, state);
    if (validationOptions?.length > 0) {
      setModalHandler(
        {
          content: validationOptions,
          modal: "formError",
        },
        dispatch
      );
    } else {
      setShowAnimation("animate-left4");
      let stepthree = { stepthree: step3, formStep: 3 };
      storelocalStorageData({
        stepthree: step3,
        stepthreeToggles: toggle,
        formStep: 4,
      });
      // to store in local and global states
      setStepFormValues(stepthree, dispatch);
      setStep(4);
      setSelectedQuestion(1);
    }
  };

  return (
    <div className="container mx-auto py-8 step1-container px-4">
      <div
        className={`flex flex-col lg:flex-row lg:justify-between items-center lg:items-start lg:flex-row ${
          screenSize?.width < 1500 && formHeightHandler("main")
        }`}
      >
        {/* left side */}
        <div
          className={`w-12/12 lg:w-[70%] lg:overflow-y-auto ${
            parseInt(browserZoomLevel) === 100 ||
            parseInt(browserZoomLevel) === 90 ||
            parseInt(browserZoomLevel) === 80
              ? formHeightHandler("left")
              : formsLeftQuestionsHeight("lg:h-[70vh]")
          }`}
        >
          {/* 1st card */}
          <div
            className={`w-full py-4 pl-4 pr-7 border-2 rounded-2xl mb-6 ${
              selectedQuestion === 1 ? "border-[#00A3DA]" : "border-[#E1F4FB]"
            }`}
          >
            {/* header */}
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center w-11/12">
                <img src={HouseQ1} alt="" className="h-12" />
                <h5 className="text-[#3A484D] font-semibold px-6 text-[17px]">
                  {state?.language?.stepThree.questionOne?.title}
                </h5>
              </div>
              <div
                className="cursor-pointer w-1/12 flex justify-end"
                onClick={() => {
                  setModalHandler(
                    {
                      content: "stepThree.questionOne",
                      modal: "step3",
                    },
                    dispatch
                  );
                }}
              >
                <img src={InfoIcon} alt="" className="h-5" />
              </div>
            </div>
            {/* content */}
            <div className="flex flex-col lg:flex-row justify-between pt-8 pb-4 lg:px-6">
              <div className="w-full py-2 lg:px-2 lg:w-4/12">
                <div
                  className={`steps-box-hover border rounded-2xl px-4 py-8 w-full h-full lg:w-12/12 hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                  ${
                    step3?.question_one?.answer_id === 26
                      ? "bg-[#01a2da] text-[#fff]"
                      : "text-[#3A484D]"
                  }
                  `}
                  onClick={() => {
                    storeStepValues(step3, "question_one", "", 26, setStep3);
                    setSelectedQuestion(2);
                    toggleHandler("doubleVitrage", false);
                    smoothScroll("content2");
                  }}
                >
                  <div className="flex flex-row lg:flex-col justify-center items-center">
                    <div className="w-4/12 lg:w-full flex justify-center">
                      {step3?.question_one?.answer_id === 26 ? (
                        <img src={House_6_On} alt="" className="h-16 " />
                      ) : (
                        <>
                          {" "}
                          <img
                            src={House_6_Off}
                            alt=""
                            className="h-16 hide-on-hover"
                          />
                          <img
                            src={House_6_On}
                            alt=""
                            className="h-16 show-on-hover"
                          />
                        </>
                      )}
                    </div>
                    <div className="w-8/12 lg:w-full">
                      <p className="text-[15px] lg:text-center font-normal py-3 px-8 lg:px-0">
                        {state?.language?.stepThree.questionOne?.answerOne}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full py-2 lg:px-2 lg:w-4/12">
                <div
                  className={`steps-box-hover border rounded-2xl px-4 py-8 w-full h-full lg:w-12/12 hover:bg-[#01a2da] hover:text-[#fff] cursor-pointer
                  ${
                    step3?.question_one?.answer_id === 27 ||
                    step3?.question_one?.answer_id === 28 ||
                    toggle?.doubleVitrage
                      ? "bg-[#01a2da] text-[#fff]"
                      : "text-[#3A484D]"
                  }
                  `}
                  onClick={() => {
                    storeStepValues(step3, "question_one", "", 0, setStep3);
                    toggleHandler(
                      "doubleVitrage",
                      toggle?.doubleVitrage ? false : true
                    );
                  }}
                >
                  {" "}
                  <div className="flex flex-row lg:flex-col justify-center items-center">
                    <div className="w-4/12 lg:w-full flex justify-center">
                      {step3?.question_one?.answer_id === 27 ||
                      step3?.question_one?.answer_id === 28 ||
                      toggle?.doubleVitrage ? (
                        <img src={House_7_On} alt="" className="h-16" />
                      ) : (
                        <>
                          <img
                            src={House_7_Off}
                            alt=""
                            className="h-16 hide-on-hover"
                          />
                          <img
                            src={House_7_On}
                            alt=""
                            className="h-16 show-on-hover"
                          />
                        </>
                      )}
                    </div>
                    <div className="w-8/12 lg:w-full">
                      <p className="text-[15px] lg:text-center font-normal py-3 px-8 lg:px-0">
                        {state?.language?.stepThree.questionOne?.answerTwo}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full py-2 lg:px-2 lg:w-4/12">
                <div
                  className={`steps-box-hover border rounded-2xl px-4 py-8 w-full h-full lg:w-12/12 hover:bg-[#01a2da] hover:text-[#fff] cursor-pointer
                  ${
                    step3?.question_one?.answer_id === 29
                      ? "bg-[#01a2da] text-[#fff]"
                      : "text-[#3A484D]"
                  }
                  `}
                  onClick={() => {
                    storeStepValues(step3, "question_one", "", 29, setStep3);
                    setSelectedQuestion(2);
                    toggleHandler("doubleVitrage", false);
                    smoothScroll("content2");
                  }}
                >
                  <div className="flex flex-row lg:flex-col justify-center items-center">
                    <div className="w-4/12 lg:w-full flex justify-center">
                      {step3?.question_one?.answer_id === 29 ? (
                        <img src={House_8_On} alt="" className="h-16" />
                      ) : (
                        <>
                          <img
                            src={House_8_Off}
                            alt=""
                            className="h-16 hide-on-hover"
                          />
                          <img
                            src={House_8_On}
                            alt=""
                            className="h-16 show-on-hover"
                          />
                        </>
                      )}
                    </div>
                    <div className="w-8/12 lg:w-full">
                      <p className="text-[15px] lg:text-center font-normal py-3 px-8 lg:px-0">
                        {state?.language?.stepThree.questionOne?.answerThree}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* boxes */}
            {toggle?.doubleVitrage && (
              <div className="flex flex-col lg:flex-row justify-between lg:px-14">
                <div className="py-2 w-full lg:w-6/12 lg:px-3">
                  <div
                    className={`border rounded-2xl px-4 py-6 w-full h-full hover:bg-[#01a2da] hover:text-[#fff] cursor-pointer
                ${
                  step3?.question_one?.answer_id === 27
                    ? "bg-[#01a2da] text-[#fff]"
                    : "text-[#3A484D]"
                }
                `}
                    onClick={() => {
                      storeStepValues(step3, "question_one", "", 27, setStep3);
                      setSelectedQuestion(2);
                      smoothScroll("content2");
                    }}
                  >
                    <p className="text-[15px] text-center my-1">
                      {state?.language?.stepThree.questionOne?.answerFour}
                    </p>
                  </div>
                </div>
                <div className="py-2 w-full lg:w-6/12 lg:px-3">
                  <div
                    className={`border rounded-2xl px-4 py-6 w-full h-full hover:bg-[#01a2da] hover:text-[#fff] cursor-pointer
                ${
                  step3?.question_one?.answer_id === 28
                    ? "bg-[#01a2da] text-[#fff]"
                    : "text-[#3A484D]"
                }
                `}
                    onClick={() => {
                      storeStepValues(step3, "question_one", "", 28, setStep3);
                      setSelectedQuestion(2);
                      smoothScroll("content2");
                    }}
                  >
                    <p className="text-[15px] text-center my-1">
                      {state?.language?.stepThree.questionOne?.answerFive}
                      
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* 2nd card */}
          <div
            className={`w-full py-4 pl-4 pr-7 border-2 rounded-2xl mb-6 ${
              selectedQuestion === 2 ? "border-[#00A3DA]" : "border-[#E1F4FB]"
            }`}
            id="content2"
          >
            {/* header */}
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center w-11/12">
                <img src={HouseQ2} alt="" className="h-12" />
                <h5 className="text-[#4A4A4A] font-[600] px-6 text-[17px]">
                  {state?.language?.stepThree.questionTwo?.title}
                </h5>
              </div>
              <div
                className="cursor-pointer w-1/12 flex justify-end"
                onClick={() => {
                  setModalHandler(
                    {
                      content: "stepThree.questionTwo",
                      modal: "step3",
                    },
                    dispatch
                  );
                }}
              >
                <img src={InfoIcon} alt="" className="h-5" />
              </div>
            </div>
            <div>
              {/* 1st section */}
              <div>
                {/* content */}
                <div className="flex flex-row lg:flex-row justify-around pt-6 lg:px-6">
                  <div className="py-3 lg:px-3 px-1 w-full lg:w-4/12">
                    <div
                      className={`border rounded-2xl h-full px-3 py-6 w-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer flex items-center justify-center
                      ${
                        step3?.question_two?.answer_id === 30
                          ? "bg-[#01a2da] text-[#fff]"
                          : "text-[#3A484D]"
                      }
                     `}
                      onClick={() => {
                        storeStepValues(
                          step3,
                          "question_two",
                          "",
                          30,
                          setStep3
                        );
                        // if tabs are alredy open of step 2 options to close it
                        let duplicate: any = { ...toggle };
                        duplicate.sectioTwoCheckBoxOne = false;
                        setToggle(duplicate);
                      }}
                    >
                      <p className="text-[16px] text-center">
                        {state?.language?.stepThree.questionTwo?.answerOne}
                      </p>
                    </div>
                  </div>
                  <div className="py-3 lg:px-3 px-1 w-full lg:w-4/12">
                    <div
                      className={`border rounded-2xl px-3 py-6 w-full h-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer flex items-center justify-center
                    ${
                      step3?.question_two?.answer_id === 31
                        ? "bg-[#01a2da] text-[#fff]"
                        : "text-[#3A484D]"
                    }
                   `}
                      onClick={() => {
                        // to store value
                        storeStepValues(
                          step3,
                          "question_two",
                          "",
                          31,
                          setStep3
                        );
                        storeStepValues(
                          step3,
                          "question_three",
                          "",
                          0,
                          setStep3
                        );
                        storeStepValues(
                          step3,
                          "question_four",
                          "",
                          0,
                          setStep3
                        );
                        setSelectedQuestion(3);
                        smoothScroll("content3");
                        // if tabs are alredy open of step 2 options to close it
                        let duplicate: any = { ...toggle };
                        duplicate.sectioTwoCheckBoxOne = false;
                        duplicate.sectioTwoCheckBoxTwo = false;
                        setToggle(duplicate);
                      }}
                    >
                      <p className="text-[16px] text-center my-1">
                        {state?.language?.stepThree.questionTwo?.answerTwo}
                      </p>
                    </div>
                  </div>
                  <div className="py-3 lg:px-3 px-1 w-full lg:w-4/12">
                    <div
                      className={`border rounded-2xl px-3 py-6 w-full h-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer flex items-center justify-center
                      ${
                        step3?.question_two?.answer_id === 32
                          ? "bg-[#01a2da] text-[#fff]"
                          : "text-[#3A484D]"
                      }
                     `}
                      onClick={() => {
                        storeStepValues(
                          step3,
                          "question_two",
                          "",
                          32,
                          setStep3
                        );
                        storeStepValues(
                          step3,
                          "question_three",
                          "",
                          0,
                          setStep3
                        );
                        storeStepValues(
                          step3,
                          "question_four",
                          "",
                          0,
                          setStep3
                        );
                        setSelectedQuestion(3);
                        smoothScroll("content3");
                        // if tabs are alredy open of step 2 options to close it
                        let duplicate: any = { ...toggle };
                        duplicate.sectioTwoCheckBoxOne = false;
                        duplicate.sectioTwoCheckBoxTwo = false;
                        setToggle(duplicate);
                      }}
                    >
                      <p className="text-[16px] text-center my-1">
                        {state?.language?.stepThree.questionTwo?.answerThree}
                      </p>
                    </div>
                  </div>
                </div>
                {/*  */}
                {step3?.question_two?.answer_id === 30 && (
                  <>
                    <div className="pb-6 px-6">
                      <label className="radio-container mt-4">
                        <span className="text-[18px] font-normal text-[#3A484D] pt-[3px]">
                          {state?.language?.stepThree.questionTwo?.answerFive}
                        </span>
                        <input
                          type="checkbox"
                          className=""
                          name="radio"
                          checked={toggle?.sectioTwoCheckBoxOne}
                          onChange={() => {
                            storeStepValues(
                              step3,
                              "question_three",
                              "",
                              0,
                              setStep3
                            );
                            toggleHandler(
                              "sectioTwoCheckBoxOne",
                              toggle?.sectioTwoCheckBoxOne ? false : true
                            );
                          }}
                        />
                        <span className="checkmark checkbox-input mt-1"></span>
                      </label>
                    </div>
                    {/* 2nd section */}
                    {toggle?.sectioTwoCheckBoxOne ? (
                      <div className="px-7">
                        <p>
                          {state?.language?.stepThree.questionTwoSubThree?.text}
                        </p>
                      </div>
                    ) : (
                      <div>
                        <div className="flex items-center">
                          <h5 className="text-[#4A4A4A] font-semibold px-9 text-[17px]">
                            {
                              state?.language?.stepThree?.questionTwoSubOne
                                ?.title
                            }
                          </h5>
                        </div>
                        {/* content */}
                        <div className="flex flex-wrap justify-between lg:justify-start lg:flex-row lg:items-center pt-4 px-6">
                          <div className="py-2 w-5/12 lg:w-2/12">
                            <div
                              className={`border rounded-2xl px-4 py-3 w-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                            ${
                              !toggle?.sectioTwoCheckBoxTwo &&
                              step3?.question_three?.answer_id === 34
                                ? "border-[#01a2da] bg-[#01a2da] text-[#fff]"
                                : "text-[#3A484D]"
                            }
                            ${toggle?.sectioTwoCheckBoxTwo && " bg-[#eee]"}
                          `}
                              onClick={() => {
                                if (!toggle?.sectioTwoCheckBoxTwo) {
                                  storeStepValues(
                                    step3,
                                    "question_three",
                                    "",
                                    34,
                                    setStep3
                                  );
                                  setSelectedQuestion(3);
                                  smoothScroll("content3");
                                }
                              }}
                            >
                              <p className="text-[14px] text-center">
                                3 - 6 cm
                              </p>
                            </div>
                          </div>
                          {/* line */}
                          <div
                            className={`hidden lg:block lg:w-[11%] h-1
                          ${
                            !toggle?.sectioTwoCheckBoxTwo &&
                            (step3?.question_three?.answer_id === 35 ||
                              step3?.question_three?.answer_id === 36 ||
                              step3?.question_three?.answer_id === 37)
                              ? "bg-[#01a2da]"
                              : "bg-[#DFE1E2]"
                          }
                         `}
                            onClick={() => {
                              if (!toggle?.sectioTwoCheckBoxTwo) {
                                storeStepValues(
                                  step3,
                                  "question_three",
                                  "",
                                  34,
                                  setStep3
                                );
                                setSelectedQuestion(3);
                                smoothScroll("content3");
                              }
                            }}
                          ></div>
                          <div className="py-2 w-5/12 lg:w-2/12">
                            <div
                              className={`border rounded-2xl px-4 py-2 w-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                            ${
                              !toggle?.sectioTwoCheckBoxTwo &&
                              step3?.question_three?.answer_id === 35
                                ? "border-[#01a2da] bg-[#01a2da] text-[#fff]"
                                : "text-[#3A484D]"
                            }
                            ${toggle?.sectioTwoCheckBoxTwo && " bg-[#eee]"}
                                   `}
                              onClick={() => {
                                if (!toggle?.sectioTwoCheckBoxTwo) {
                                  storeStepValues(
                                    step3,
                                    "question_three",
                                    "",
                                    35,
                                    setStep3
                                  );
                                  setSelectedQuestion(3);
                                  smoothScroll("content3");
                                }
                              }}
                            >
                              <p className="text-[14px] text-center my-1">
                                8 - 12 cm
                              </p>
                            </div>
                          </div>
                          {/* line */}
                          <div
                            className={`hidden lg:block lg:w-[11%] h-1 
                          ${
                            !toggle?.sectioTwoCheckBoxTwo &&
                            (step3?.question_three?.answer_id === 36 ||
                              step3?.question_three?.answer_id === 37)
                              ? "bg-[#01a2da]"
                              : "bg-[#DFE1E2]"
                          }
                          `}
                          ></div>
                          <div className="py-2 w-5/12 lg:w-2/12">
                            <div
                              className={`border rounded-2xl px-4 py-2 w-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                            ${
                              !toggle?.sectioTwoCheckBoxTwo &&
                              step3?.question_three?.answer_id === 36
                                ? "border-[#01a2da] bg-[#01a2da] text-[#fff]"
                                : "text-[#3A484D]"
                            }
                            ${toggle?.sectioTwoCheckBoxTwo && " bg-[#eee]"}
                                   `}
                              onClick={() => {
                                if (!toggle?.sectioTwoCheckBoxTwo) {
                                  storeStepValues(
                                    step3,
                                    "question_three",
                                    "",
                                    36,
                                    setStep3
                                  );
                                  setSelectedQuestion(3);
                                  smoothScroll("content3");
                                }
                              }}
                            >
                              <p className="text-[14px] text-center my-1">
                                14 - 18 cm
                              </p>
                            </div>
                          </div>
                          {/* line */}
                          <div
                            className={`hidden lg:block lg:w-[11%] h-1
                          ${
                            !toggle?.sectioTwoCheckBoxTwo &&
                            step3?.question_three?.answer_id === 37
                              ? "bg-[#01a2da] text-[#fff]"
                              : "bg-[#DFE1E2]"
                          }
                                 `}
                            onClick={() => {
                              if (!toggle?.sectioTwoCheckBoxTwo) {
                                storeStepValues(
                                  step3,
                                  "question_three",
                                  "",
                                  34,
                                  setStep3
                                );
                                setSelectedQuestion(3);
                                smoothScroll("content3");
                              }
                            }}
                          ></div>
                          <div className="py-2 w-5/12 lg:w-2/12">
                            <div
                              className={`border rounded-2xl px-4 py-2 w-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                            ${
                              !toggle?.sectioTwoCheckBoxTwo &&
                              step3?.question_three?.answer_id === 37
                                ? "border-[#01a2da] bg-[#01a2da] text-[#fff]"
                                : "text-[#3A484D]"
                            }
                            ${toggle?.sectioTwoCheckBoxTwo && " bg-[#eee]"}
                                   `}
                              onClick={() => {
                                if (!toggle?.sectioTwoCheckBoxTwo) {
                                  storeStepValues(
                                    step3,
                                    "question_three",
                                    "",
                                    37,
                                    setStep3
                                  );
                                  setSelectedQuestion(3);
                                  smoothScroll("content3");
                                }
                              }}
                            >
                              <p className="text-[14px] text-center my-1">
                                20 cm +
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="pb-6 px-6">
                          <label className="radio-container mt-4">
                            <span className="text-[18px] font-normal text-[#3A484D] pt-[3px]">
                              {
                                state?.language?.stepThree?.questionTwoSubOne
                                  ?.answerFive
                              }
                            </span>
                            <input
                              type="checkbox"
                              className=""
                              name="radio"
                              checked={toggle?.sectioTwoCheckBoxTwo}
                              onChange={() => {
                                storeStepValues(
                                  step3,
                                  "question_three",
                                  "",
                                  0,
                                  setStep3
                                );
                                storeStepValues(
                                  step3,
                                  "question_four",
                                  "",
                                  0,
                                  setStep3
                                );
                                //
                                toggleHandler(
                                  "sectioTwoCheckBoxTwo",
                                  toggle?.sectioTwoCheckBoxTwo ? false : true
                                );
                              }}
                            />
                            <span className="checkmark checkbox-input mt-1"></span>
                          </label>
                        </div>
                        {/* checbox 2 */}
                        {toggle?.sectioTwoCheckBoxTwo && (
                          <div>
                            <div className="flex items-center">
                              <h5 className="text-[#4A4A4A] font-semibold px-9 text-[17px]">
                                {
                                  state?.language?.stepThree?.questionTwoSubTwo
                                    ?.title
                                }
                              </h5>
                            </div>
                            <div className="px-9">
                              <label className="radio-container mt-4">
                                <span className="text-[18px] font-normal text-[#3A484D] pt-[3px]">
                                  {
                                    state?.language?.stepThree
                                      ?.questionTwoSubTwo?.answerOne
                                  }
                                </span>
                                <input
                                  type="radio"
                                  className=""
                                  name="radio4"
                                  checked={
                                    step3?.question_four?.answer_id === 39
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    storeStepValues(
                                      step3,
                                      "question_four",
                                      "",
                                      39,
                                      setStep3
                                    );
                                    setSelectedQuestion(3);
                                    smoothScroll("content3");
                                  }}
                                />
                                <span className="checkmark checkbox-input mt-1"></span>
                              </label>
                              <label className="radio-container mt-4">
                                <span className="text-[18px] font-normal text-[#3A484D] pt-[3px]">
                                  {
                                    state?.language?.stepThree
                                      ?.questionTwoSubTwo?.answerTwo
                                  }
                                </span>
                                <input
                                  type="radio"
                                  className=""
                                  name="radio4"
                                  checked={
                                    step3?.question_four?.answer_id === 40
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    storeStepValues(
                                      step3,
                                      "question_four",
                                      "",
                                      40,
                                      setStep3
                                    );
                                    setSelectedQuestion(3);
                                    smoothScroll("content3");
                                  }}
                                />
                                <span className="checkmark checkbox-input mt-1"></span>
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          {/* 3rd card */}
          <div
            className={`w-full py-4 pl-4 pr-7 border-2 rounded-2xl mb-6  ${
              selectedQuestion === 3 ? "border-[#00A3DA]" : "border-[#E1F4FB]"
            }`}
            id="content3"
          >
            {/* header */}
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center w-11/12">
                <img src={HouseQ3} alt="" className="h-12" />
                <h5 className="text-[#4A4A4A] font-[600] px-6 text-[17px]">
                  {state?.language?.stepThree.questionThree?.title}
                </h5>
              </div>
              <div
                className="cursor-pointer w-1/12 flex justify-end"
                onClick={() => {
                  setModalHandler(
                    {
                      content: "stepThree.questionThree",
                      modal: "step3",
                    },
                    dispatch
                  );
                }}
              >
                <img src={InfoIcon} alt="" className="h-5" />
              </div>
            </div>
            <div>
              {/* 1st section */}
              <div>
                {/* content */}
                <div className="flex flex-row lg:flex-row justify-around pt-6 lg:px-6">
                  <div className="py-3 px-1 lg:px-3 w-full lg:w-4/12">
                    <div
                      className={`border rounded-2xl h-full px-3 py-6 w-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer flex items-center justify-center ${
                        step3?.question_six?.answer_id === 42
                          ? "bg-[#01a2da] text-[#fff]"
                          : "text-[#3A484D]"
                      }
                   `}
                      onClick={() => {
                        storeStepValues(
                          step3,
                          "question_six",
                          "",
                          42,
                          setStep3
                        );
                        // if tabs are alredy open of step 2 options to close it
                        let duplicate: any = { ...toggle };
                        duplicate.sectioThreeCheckBoxOne = false;
                        setToggle(duplicate);
                      }}
                    >
                      <p className="text-[16px] text-center">
                        {state?.language?.stepThree.questionThree?.answerOne}
                      </p>
                    </div>
                  </div>
                  <div className="py-3 px-1 lg:px-3 w-full lg:w-4/12">
                    <div
                      className={`border rounded-2xl px-3 py-6 w-full h-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer flex items-center justify-center
                     ${
                       step3?.question_six?.answer_id === 43
                         ? "bg-[#01a2da] text-[#fff]"
                         : "text-[#3A484D]"
                     }
                 `}
                      onClick={() => {
                        storeStepValues(
                          step3,
                          "question_six",
                          "",
                          43,
                          setStep3
                        );
                        setSelectedQuestion(4);
                        smoothScroll("content4");
                        // if tabs are alredy open of step 2 options to close it
                        let duplicate: any = { ...toggle };
                        duplicate.sectioThreeCheckBoxOne = false;
                        duplicate.sectioThreeCheckBoxTwo = false;
                        setToggle(duplicate);
                        //
                        storeStepValues(
                          step3,
                          "question_seven",
                          "",
                          0,
                          setStep3
                        );
                        storeStepValues(
                          step3,
                          "question_eight",
                          "",
                          0,
                          setStep3
                        );
                        storeStepValues(
                          step3,
                          "question_nine",
                          "",
                          0,
                          setStep3
                        );
                      }}
                    >
                      <p className="text-[16px] text-center my-1">
                        {state?.language?.stepThree.questionThree?.answerTwo}
                      </p>
                    </div>
                  </div>
                  <div className="py-3 px-1 lg:px-3 w-full lg:w-4/12">
                    <div
                      className={`border rounded-2xl px-3 py-6 w-full h-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer flex items-center justify-center
                     ${
                       step3?.question_six?.answer_id === 44
                         ? "bg-[#01a2da] text-[#fff]"
                         : "text-[#3A484D]"
                     }
                 `}
                      onClick={() => {
                        storeStepValues(
                          step3,
                          "question_six",
                          "",
                          44,
                          setStep3
                        );
                        setSelectedQuestion(4);
                        smoothScroll("content4");
                        // if tabs are alredy open of step 2 options to close it
                        let duplicate: any = { ...toggle };
                        duplicate.sectioThreeCheckBoxOne = false;
                        duplicate.sectioThreeCheckBoxTwo = false;
                        setToggle(duplicate);
                        //
                        storeStepValues(
                          step3,
                          "question_seven",
                          "",
                          0,
                          setStep3
                        );
                        storeStepValues(
                          step3,
                          "question_eight",
                          "",
                          0,
                          setStep3
                        );
                        storeStepValues(
                          step3,
                          "question_nine",
                          "",
                          0,
                          setStep3
                        );
                      }}
                    >
                      <p className="text-[16px] text-center my-1">
                        {state?.language?.stepThree.questionThree?.answerThree}
                      </p>
                    </div>
                  </div>
                </div>
                {/*  */}
                {step3?.question_six?.answer_id === 42 && (
                  <>
                    <div className="pb-6 px-6">
                      <label className="radio-container mt-4">
                        <span className="text-[18px] font-normal text-[#3A484D] pt-[3px]">
                          {state?.language?.stepThree.questionThree?.answerFive}
                        </span>
                        <input
                          type="checkbox"
                          className=""
                          name="radio5"
                          checked={toggle?.sectioThreeCheckBoxOne}
                          onChange={() => {
                            toggleHandler(
                              "sectioThreeCheckBoxOne",
                              toggle?.sectioThreeCheckBoxOne ? false : true
                            );
                            storeStepValues(
                              step3,
                              "question_seven",
                              "",
                              0,
                              setStep3
                            );
                            storeStepValues(
                              step3,
                              "question_eight",
                              "",
                              0,
                              setStep3
                            );
                            storeStepValues(
                              step3,
                              "question_nine",
                              "",
                              0,
                              setStep3
                            );
                          }}
                        />
                        <span className="checkmark checkbox-input mt-1"></span>
                      </label>
                    </div>
                    {/* 2nd section */}
                    {toggle?.sectioThreeCheckBoxOne ? (
                      <div className="px-7">
                        <p>
                          {state?.language?.stepThree.questionTwoSubThree?.text}
                        </p>
                      </div>
                    ) : (
                      <div>
                        <div className="flex items-center">
                          <h5 className="text-[#4A4A4A] font-semibold px-9 text-[17px]">
                            {
                              state?.language?.stepThree?.questionTwoSubOne
                                ?.title
                            }
                          </h5>
                        </div>
                        {/* content */}
                        <div className="flex flex-wrap justify-between lg:justify-start lg:flex-row items-center pt-4 px-6">
                          <div className="py-2 w-3/12 lg:w-2/12">
                            <div
                              className={`border rounded-2xl px-2 lg:px-4 py-3 w-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                  ${
                    !toggle?.sectioThreeCheckBoxTwo &&
                    step3?.question_seven?.answer_id === 46
                      ? "border-[#01a2da] bg-[#01a2da] text-[#fff]"
                      : "text-[#3A484D]"
                  }
                  ${toggle?.sectioThreeCheckBoxTwo && " bg-[#eee]"}
              `}
                              onClick={() => {
                                if (!toggle?.sectioThreeCheckBoxTwo) {
                                  storeStepValues(
                                    step3,
                                    "question_seven",
                                    "",
                                    46,
                                    setStep3
                                  );
                                  setSelectedQuestion(4);
                                  smoothScroll("content4");
                                }
                              }}
                            >
                              <p className="text-[14px] text-center">
                                3 - 6 cm
                              </p>
                            </div>
                          </div>
                          {/* line */}
                          <div
                            className={`block w-[12.5%] lg:w-[25%] h-1 
                ${
                  !toggle?.sectioThreeCheckBoxTwo &&
                  (step3?.question_seven?.answer_id === 47 ||
                    step3?.question_seven?.answer_id === 48)
                    ? "bg-[#01a2da]"
                    : "bg-[#DFE1E2]"
                }
                `}
                          ></div>
                          <div className="py-2 w-3/12 lg:w-2/12">
                            <div
                              className={`border rounded-2xl px-2 lg:px-4 py-2 w-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                                  ${
                                    !toggle?.sectioThreeCheckBoxTwo &&
                                    step3?.question_seven?.answer_id === 47
                                      ? "border-[#01a2da] bg-[#01a2da] text-[#fff]"
                                      : "text-[#3A484D]"
                                  }
                                  ${
                                    toggle?.sectioThreeCheckBoxTwo &&
                                    " bg-[#eee]"
                                  }
                              `}
                              onClick={() => {
                                if (!toggle?.sectioThreeCheckBoxTwo) {
                                  storeStepValues(
                                    step3,
                                    "question_seven",
                                    "",
                                    47,
                                    setStep3
                                  );
                                  setSelectedQuestion(4);
                                  smoothScroll("content4");
                                }
                              }}
                            >
                              <p className="text-[14px] text-center my-1">
                                7 - 9 cm
                              </p>
                            </div>
                          </div>
                          {/* line */}
                          <div
                            className={`block w-[12.5%] lg:w-[25%] h-1
                                ${
                                  !toggle?.sectioThreeCheckBoxTwo &&
                                  step3?.question_seven?.answer_id === 48
                                    ? "bg-[#01a2da]"
                                    : "bg-[#DFE1E2]"
                                }
                               `}
                          ></div>
                          <div className="py-2 w-3/12 lg:w-2/12">
                            <div
                              className={`border rounded-2xl px-2 lg:px-4 py-2 w-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                                  ${
                                    !toggle?.sectioThreeCheckBoxTwo &&
                                    step3?.question_seven?.answer_id === 48
                                      ? "border-[#01a2da] bg-[#01a2da] text-[#fff]"
                                      : "text-[#3A484D]"
                                  }
                                  ${
                                    toggle?.sectioThreeCheckBoxTwo &&
                                    " bg-[#eee]"
                                  }
                              `}
                              onClick={() => {
                                if (!toggle?.sectioThreeCheckBoxTwo) {
                                  storeStepValues(
                                    step3,
                                    "question_seven",
                                    "",
                                    48,
                                    setStep3
                                  );
                                  setSelectedQuestion(4);
                                  smoothScroll("content4");
                                }
                              }}
                            >
                              <p className="text-[14px] text-center my-1">
                                10 cm +
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="pb-6 px-6">
                          <label className="radio-container mt-4">
                            <span className="text-[18px] font-normal text-[#3A484D] pt-[3px]">
                              {
                                state?.language?.stepThree?.questionTwoSubOne
                                  ?.answerFive
                              }
                            </span>
                            <input
                              type="checkbox"
                              className=""
                              name="radio6"
                              checked={toggle?.sectioThreeCheckBoxTwo}
                              onChange={() => {
                                storeStepValues(
                                  step3,
                                  "question_seven",
                                  "",
                                  0,
                                  setStep3
                                );
                                storeStepValues(
                                  step3,
                                  "question_nine",
                                  "",
                                  0,
                                  setStep3
                                );
                                storeStepValues(
                                  step3,
                                  "question_eight",
                                  "",
                                  0,
                                  setStep3
                                );
                                //
                                toggleHandler(
                                  "sectioThreeCheckBoxTwo",
                                  toggle?.sectioThreeCheckBoxTwo ? false : true
                                );
                              }}
                            />
                            <span className="checkmark checkbox-input mt-1"></span>
                          </label>
                        </div>
                        {toggle?.sectioThreeCheckBoxTwo && (
                          <div>
                            <div className="flex items-center">
                              <h5 className="text-[#4A4A4A] font-semibold px-9 text-[17px]">
                                {
                                  state?.language?.stepThree
                                    ?.questionThreeSubTwo?.title
                                }
                              </h5>
                            </div>
                            <div className="px-9">
                              <label className="radio-container mt-4">
                                <span className="text-[18px] font-normal text-[#3A484D] pt-[3px]">
                                  {
                                    state?.language?.stepThree
                                      ?.questionThreeSubTwo?.answerOne
                                  }
                                </span>
                                <input
                                  type="checkbox"
                                  className=""
                                  name="radio"
                                  checked={
                                    step3?.question_eight?.answer_id === 50
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    storeStepValues(
                                      step3,
                                      "question_eight",
                                      "",
                                      50,
                                      setStep3
                                    );
                                    setSelectedQuestion(4);
                                    // smoothScroll("content4");
                                  }}
                                />
                                <span className="checkmark checkbox-input mt-1"></span>
                              </label>
                              <label className="radio-container mt-4">
                                <span className="text-[18px] font-normal text-[#3A484D] pt-[3px]">
                                  {
                                    state?.language?.stepThree
                                      ?.questionThreeSubTwo?.answerTwo
                                  }
                                </span>
                                <input
                                  type="checkbox"
                                  className=""
                                  name="radio"
                                  checked={
                                    step3?.question_eight?.answer_id === 51
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    storeStepValues(
                                      step3,
                                      "question_eight",
                                      "",
                                      51,
                                      setStep3
                                    );
                                    storeStepValues(
                                      step3,
                                      "question_nine",
                                      "",
                                      0,
                                      setStep3
                                    );
                                    setSelectedQuestion(4);
                                    smoothScroll("content4");
                                  }}
                                />
                                <span className="checkmark checkbox-input mt-1"></span>
                              </label>
                              <label className="radio-container mt-4">
                                <span className="text-[18px] font-normal text-[#3A484D] pt-[3px]">
                                  {
                                    state?.language?.stepThree
                                      ?.questionThreeSubTwo?.answerThree
                                  }
                                </span>
                                <input
                                  type="checkbox"
                                  className=""
                                  name="radio"
                                  checked={
                                    step3?.question_eight?.answer_id === 52
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    storeStepValues(
                                      step3,
                                      "question_eight",
                                      "",
                                      52,
                                      setStep3
                                    );
                                    storeStepValues(
                                      step3,
                                      "question_nine",
                                      "",
                                      0,
                                      setStep3
                                    );
                                    setSelectedQuestion(4);
                                    smoothScroll("content4");
                                  }}
                                />
                                <span className="checkmark checkbox-input mt-1"></span>
                              </label>
                            </div>
                          </div>
                        )}
                        {step3?.question_eight?.answer_id === 50 && (
                          <div className="pt-4">
                            <div className="flex items-center">
                              <h5 className="text-[#4A4A4A] font-semibold px-9 text-[17px]">
                                {
                                  state?.language?.stepThree
                                    ?.questionThreeSubThree?.title
                                }
                              </h5>
                            </div>
                            <div className="px-9">
                              <label className="radio-container mt-4">
                                <span className="text-[18px] font-normal text-[#3A484D] pt-[3px]">
                                  {
                                    state?.language?.stepThree
                                      ?.questionThreeSubThree?.answerOne
                                  }
                                </span>
                                <input
                                  type="radio"
                                  className=""
                                  name="radio6"
                                  checked={
                                    step3?.question_nine?.answer_id === 53
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    storeStepValues(
                                      step3,
                                      "question_nine",
                                      "",
                                      53,
                                      setStep3
                                    );
                                    setSelectedQuestion(4);
                                    smoothScroll("content4");
                                  }}
                                />
                                <span className="checkmark checkbox-input mt-1"></span>
                              </label>
                              <label className="radio-container mt-4">
                                <span className="text-[18px] font-normal text-[#3A484D] pt-[3px]">
                                  {
                                    state?.language?.stepThree
                                      ?.questionThreeSubThree?.answerTwo
                                  }
                                </span>
                                <input
                                  type="radio"
                                  className=""
                                  name="radio6"
                                  checked={
                                    step3?.question_nine?.answer_id === 54
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    storeStepValues(
                                      step3,
                                      "question_nine",
                                      "",
                                      54,
                                      setStep3
                                    );
                                    setSelectedQuestion(4);
                                    smoothScroll("content4");
                                  }}
                                />
                                <span className="checkmark checkbox-input mt-1"></span>
                              </label>
                              <label className="radio-container mt-4">
                                <span className="text-[18px] font-normal text-[#3A484D] pt-[3px]">
                                  {
                                    state?.language?.stepThree
                                      ?.questionThreeSubThree?.answerThree
                                  }
                                </span>
                                <input
                                  type="radio"
                                  className=""
                                  name="radio6"
                                  checked={
                                    step3?.question_nine?.answer_id === 55
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    storeStepValues(
                                      step3,
                                      "question_nine",
                                      "",
                                      55,
                                      setStep3
                                    );
                                    setSelectedQuestion(4);
                                    smoothScroll("content4");
                                  }}
                                />
                                <span className="checkmark checkbox-input mt-1"></span>
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          {/* 4th card */}
          <div
            className={`w-full py-4 pl-4 pr-7 border-2 rounded-2xl mb-6 ${
              selectedQuestion === 4 ? "border-[#00A3DA]" : "border-[#E1F4FB]"
            }`}
            id="content4"
          >
            {/* header */}
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center w-11/12">
                <img src={HouseQ4} alt="" className="h-12" />
                <h5 className="text-[#4A4A4A] font-[600] px-6 text-[17px]">
                  {state?.language?.stepThree.questionFour?.title}
                </h5>
              </div>
              <div
                className="cursor-pointer w-1/12 flex justify-end"
                onClick={() => {
                  setModalHandler(
                    {
                      content: "stepThree.questionFour",
                      modal: "step3",
                    },
                    dispatch
                  );
                }}
              >
                <img src={InfoIcon} alt="" className="h-5" />
              </div>
            </div>
            <div>
              {/* 1st section */}
              <div>
                {/* content */}
                <div className="flex flex-row lg:flex-row justify-around pt-6 lg:px-6">
                  <div className="py-3 px-1 lg:px-4 w-full lg:w-4/12">
                    <div
                      className={`border rounded-2xl h-full px-3 py-6 w-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer flex items-center justify-center
                          ${
                            step3?.question_ten?.answer_id === 56
                              ? "bg-[#01a2da] text-[#fff]"
                              : "text-[#3A484D]"
                          }
                      `}
                      onClick={() => {
                        storeStepValues(
                          step3,
                          "question_ten",
                          "",
                          56,
                          setStep3
                        );
                        smoothScroll("content5");
                        // if tabs are alredy open of step 2 options to close it
                        let duplicate: any = { ...toggle };
                        duplicate.sectioFourCheckBoxOne = false;
                        // duplicate.sectioFourCheckBoxTwo = false;
                        setToggle(duplicate);
                      }}
                    >
                      <p className="text-[16px] text-center">
                        {state?.language?.stepThree.questionFour?.answerOne}
                      </p>
                    </div>
                  </div>
                  <div className="py-3 px-1 lg:px-4 w-full lg:w-4/12">
                    <div
                      className={`border rounded-2xl px-3 py-6 w-full h-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer flex items-center justify-center
                                      ${
                                        step3?.question_ten?.answer_id === 59
                                          ? "bg-[#01a2da] text-[#fff]"
                                          : "text-[#3A484D]"
                                      }
                                     `}
                      onClick={() => {
                        storeStepValues(
                          step3,
                          "question_ten",
                          "",
                          59,
                          setStep3
                        );
                        storeStepValues(
                          step3,
                          "question_thirteen",
                          "",
                          0,
                          setStep3
                        );
                        storeStepValues(
                          step3,
                          "question_eleven",
                          "",
                          0,
                          setStep3
                        );
                        storeStepValues(
                          step3,
                          "question_twelve",
                          "",
                          0,
                          setStep3
                        );
                        setSelectedQuestion(4);
                        smoothScroll("content5");
                        // if tabs are alredy open of step 2 options to close it
                        let duplicate: any = { ...toggle };
                        duplicate.sectioFourCheckBoxOne = false;
                        duplicate.sectioFourCheckBoxTwo = false;
                        setToggle(duplicate);
                      }}
                    >
                      <p className="text-[16px] text-center my-1">
                        {state?.language?.stepThree.questionFour?.answerTwo}
                      </p>
                    </div>
                  </div>
                  <div className="py-3 px-1 lg:px-4 w-full lg:w-4/12">
                    <div
                      className={`border rounded-2xl px-3 py-6 w-full h-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer flex items-center justify-center
                                      ${
                                        step3?.question_ten?.answer_id === 60
                                          ? "bg-[#01a2da] text-[#fff]"
                                          : "text-[#3A484D]"
                                      }
                                     `}
                      onClick={() => {
                        storeStepValues(
                          step3,
                          "question_ten",
                          "",
                          60,
                          setStep3
                        );
                        storeStepValues(
                          step3,
                          "question_thirteen",
                          "",
                          0,
                          setStep3
                        );
                        storeStepValues(
                          step3,
                          "question_eleven",
                          "",
                          0,
                          setStep3
                        );
                        storeStepValues(
                          step3,
                          "question_twelve",
                          "",
                          0,
                          setStep3
                        );
                        setSelectedQuestion(4);
                        smoothScroll("content5");
                        // if tabs are alredy open of step 2 options to close it
                        let duplicate: any = { ...toggle };
                        duplicate.sectioFourCheckBoxOne = false;
                        duplicate.sectioFourCheckBoxTwo = false;
                        setToggle(duplicate);
                      }}
                    >
                      <p className="text-[16px] text-center my-1">
                        {state?.language?.stepThree.questionFour?.answerThree}
                      </p>
                    </div>
                  </div>
                </div>
                {/*  */}
                {step3?.question_ten?.answer_id === 56 && (
                  <>
                    <div className="pb-6 px-6">
                      <label className="radio-container mt-4">
                        <span className="text-[18px] font-normal text-[#3A484D] pt-[3px]">
                          {state?.language?.stepThree.questionFour?.answerFive}
                        </span>
                        <input
                          type="checkbox"
                          className=""
                          name="radio"
                          onChange={() => {
                            toggleHandler(
                              "sectioFourCheckBoxOne",
                              toggle?.sectioFourCheckBoxOne ? false : true
                            );
                            storeStepValues(
                              step3,
                              "question_thirteen",
                              "",
                              0,
                              setStep3
                            );
                            storeStepValues(
                              step3,
                              "question_eleven",
                              "",
                              0,
                              setStep3
                            );
                            storeStepValues(
                              step3,
                              "question_twelve",
                              "",
                              0,
                              setStep3
                            );
                            // smoothScroll("content5");
                          }}
                        />
                        <span className="checkmark checkbox-input mt-1"></span>
                      </label>
                    </div>
                    {/* 2nd section */}
                    {toggle?.sectioFourCheckBoxOne ? (
                      <div className="px-7">
                        <p>
                          {state?.language?.stepThree.questionTwoSubThree?.text}
                        </p>
                      </div>
                    ) : (
                      <div>
                        <div className="flex items-center">
                          <h5 className="text-[#4A4A4A] font-semibold px-9 text-[17px]">
                            {
                              state?.language?.stepThree?.questionFourSubOne
                                ?.title
                            }
                          </h5>
                        </div>
                        {/* content */}
                        <div className="flex flex-wrap justify-between lg:justify-start lg:flex-row items-center pt-4 px-6">
                          <div className="py-2 w-3/12 lg:w-2/12">
                            <div
                              className={`border rounded-2xl px-2 lg:px-4 py-3 w-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                                ${
                                  !toggle?.sectioFourCheckBoxTwo &&
                                  step3?.question_eleven?.answer_id === 62
                                    ? "border-[#01a2da] bg-[#01a2da] text-[#fff]"
                                    : "text-[#3A484D]"
                                }
                                ${toggle?.sectioFourCheckBoxTwo && " bg-[#eee]"}
                              `}
                              onClick={() => {
                                if (!toggle?.sectioFourCheckBoxTwo) {
                                  storeStepValues(
                                    step3,
                                    "question_eleven",
                                    "",
                                    62,
                                    setStep3
                                  );
                                  smoothScroll("content5");
                                  setSelectedQuestion(4);
                                }
                              }}
                            >
                              <p className="text-[14px] text-center">
                                2 - 5 cm
                              </p>
                            </div>
                          </div>
                          {/* line */}
                          <div
                            className={`block w-[12.5%] lg:w-[25%] h-1 
                ${
                  !toggle?.sectioFourCheckBoxTwo &&
                  (step3?.question_eleven?.answer_id === 63 ||
                    step3?.question_eleven?.answer_id === 64)
                    ? "bg-[#01a2da]"
                    : "bg-[#DFE1E2]"
                }
                `}
                          ></div>
                          <div className="py-2 w-3/12 lg:w-2/12">
                            <div
                              className={`border rounded-2xl px-2 lg:px-4 py-2 w-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                  ${
                    !toggle?.sectioFourCheckBoxTwo &&
                    step3?.question_eleven?.answer_id === 63
                      ? "border-[#01a2da] bg-[#01a2da] text-[#fff]"
                      : "text-[#3A484D]"
                  }
                  ${toggle?.sectioFourCheckBoxTwo && " bg-[#eee]"}
                 `}
                              onClick={() => {
                                if (!toggle?.sectioFourCheckBoxTwo) {
                                  storeStepValues(
                                    step3,
                                    "question_eleven",
                                    "",
                                    63,
                                    setStep3
                                  );
                                  smoothScroll("content5");
                                  setSelectedQuestion(4);
                                }
                              }}
                            >
                              <p className="text-[14px] text-center my-1">
                                6 - 9 cm
                              </p>
                            </div>
                          </div>
                          {/* line */}
                          <div
                            className={`block w-[12.5%] lg:w-[25%] h-1 
                ${
                  !toggle?.sectioFourCheckBoxTwo &&
                  step3?.question_eleven?.answer_id === 64
                    ? "bg-[#01a2da]"
                    : "bg-[#DFE1E2]"
                }
                `}
                          ></div>
                          <div className="py-2 w-3/12 lg:w-2/12">
                            <div
                              className={`border rounded-2xl px-2 lg:px-4 py-2 w-full hover:bg-[#01a2da] text-[#3A484D] hover:text-[#fff] cursor-pointer
                   ${
                     !toggle?.sectioFourCheckBoxTwo &&
                     step3?.question_eleven?.answer_id === 64
                       ? "border-[#01a2da] bg-[#01a2da] text-[#fff]"
                       : "text-[#3A484D]"
                   }
                   ${toggle?.sectioFourCheckBoxTwo && " bg-[#eee]"}
                 `}
                              onClick={() => {
                                if (!toggle?.sectioFourCheckBoxTwo) {
                                  storeStepValues(
                                    step3,
                                    "question_eleven",
                                    "",
                                    64,
                                    setStep3
                                  );
                                  smoothScroll("content5");
                                  setSelectedQuestion(4);
                                }
                              }}
                            >
                              <p className="text-[14px] text-center my-1">
                                10 cm +
                              </p>
                            </div>
                          </div>
                        </div>
                        {/*  */}
                        <div className="pb-6 px-6">
                          <label className="radio-container mt-4">
                            <span className="text-[18px] font-normal text-[#3A484D] pt-[3px]">
                              {
                                state?.language?.stepThree?.questionFourSubOne
                                  ?.answerFour
                              }
                            </span>
                            <input
                              type="checkbox"
                              className=""
                              name="radio"
                              checked={toggle?.sectioFourCheckBoxTwo}
                              onChange={() => {
                                storeStepValues(
                                  step3,
                                  "question_eleven",
                                  "",
                                  0,
                                  setStep3
                                );
                                toggleHandler(
                                  "sectioFourCheckBoxTwo",
                                  toggle?.sectioFourCheckBoxTwo ? false : true
                                );
                                storeStepValues(
                                  step3,
                                  "question_twelve",
                                  "",
                                  0,
                                  setStep3
                                );
                                storeStepValues(
                                  step3,
                                  "question_thirteen",
                                  "",
                                  0,
                                  setStep3
                                );
                                smoothScroll("content5");
                              }}
                            />
                            <span className="checkmark checkbox-input mt-1"></span>
                          </label>
                        </div>
                        {toggle?.sectioFourCheckBoxTwo && (
                          <div>
                            <div className="flex items-center">
                              <h5 className="text-[#4A4A4A] font-semibold px-9 text-[17px]">
                                {
                                  state?.language?.stepThree?.questionFourSubTwo
                                    ?.title
                                }
                              </h5>
                            </div>
                            <div className="px-9">
                              <label className="radio-container mt-4">
                                <span className="text-[18px] font-normal text-[#3A484D] pt-[3px]">
                                  {
                                    state?.language?.stepThree
                                      ?.questionFourSubTwo?.answerOne
                                  }
                                </span>
                                <input
                                  type="radio"
                                  className=""
                                  name="radio7"
                                  checked={
                                    step3?.question_twelve?.answer_id === 66
                                      ? true
                                      : false
                                  }
                                  onClick={() => {
                                    storeStepValues(
                                      step3,
                                      "question_twelve",
                                      "",
                                      66,
                                      setStep3
                                    );
                                    smoothScroll("content5");
                                    setSelectedQuestion(4);
                                  }}
                                />
                                <span className="checkmark checkbox-input mt-1"></span>
                              </label>
                              <label className="radio-container mt-4">
                                <span className="text-[18px] font-normal text-[#3A484D] pt-[3px]">
                                  {
                                    state?.language?.stepThree
                                      ?.questionFourSubTwo?.answerTwo
                                  }
                                </span>
                                <input
                                  type="radio"
                                  className=""
                                  name="radio7"
                                  checked={
                                    step3?.question_twelve?.answer_id === 67
                                      ? true
                                      : false
                                  }
                                  onClick={() => {
                                    storeStepValues(
                                      step3,
                                      "question_twelve",
                                      "",
                                      67,
                                      setStep3
                                    );
                                    storeStepValues(
                                      step3,
                                      "question_thirteen",
                                      "",
                                      0,
                                      setStep3
                                    );
                                    smoothScroll("content5");
                                    setSelectedQuestion(4);
                                  }}
                                />
                                <span className="checkmark checkbox-input mt-1"></span>
                              </label>
                              <label className="radio-container mt-4">
                                <span className="text-[18px] font-normal text-[#3A484D] pt-[3px]">
                                  {
                                    state?.language?.stepThree
                                      ?.questionFourSubTwo?.answerThree
                                  }
                                </span>
                                <input
                                  type="radio"
                                  className=""
                                  name="radio7"
                                  checked={
                                    step3?.question_twelve?.answer_id === 68
                                      ? true
                                      : false
                                  }
                                  onClick={() => {
                                    storeStepValues(
                                      step3,
                                      "question_twelve",
                                      "",
                                      68,
                                      setStep3
                                    );
                                    storeStepValues(
                                      step3,
                                      "question_thirteen",
                                      "",
                                      0,
                                      setStep3
                                    );
                                    smoothScroll("content5");
                                    setSelectedQuestion(4);
                                  }}
                                />
                                <span className="checkmark checkbox-input mt-1"></span>
                              </label>
                            </div>
                          </div>
                        )}
                        {step3?.question_twelve?.answer_id === 66 && (
                          <div className="pt-4">
                            <div className="flex items-center">
                              <h5 className="text-[#4A4A4A] font-semibold px-9 text-[17px]">
                                {
                                  state?.language?.stepThree
                                    ?.questionFourSubThree?.title
                                }
                              </h5>
                            </div>
                            <div className="px-9">
                              <label className="radio-container mt-4">
                                <span className="text-[18px] font-normal text-[#3A484D] pt-[3px]">
                                  {
                                    state?.language?.stepThree
                                      ?.questionFourSubThree?.answerOne
                                  }
                                </span>
                                <input
                                  type="radio"
                                  className=""
                                  name="radio8"
                                  checked={
                                    step3?.question_thirteen?.answer_id === 69
                                      ? true
                                      : false
                                  }
                                  onClick={() => {
                                    storeStepValues(
                                      step3,
                                      "question_thirteen",
                                      "",
                                      69,
                                      setStep3
                                    );
                                    smoothScroll("content5");
                                    setSelectedQuestion(4);
                                  }}
                                />
                                <span className="checkmark checkbox-input mt-1"></span>
                              </label>
                              <label className="radio-container mt-4">
                                <span className="text-[18px] font-normal text-[#3A484D] pt-[3px]">
                                  {
                                    state?.language?.stepThree
                                      ?.questionFourSubThree?.answerTwo
                                  }
                                </span>
                                <input
                                  type="radio"
                                  className=""
                                  name="radio8"
                                  checked={
                                    step3?.question_thirteen?.answer_id === 70
                                      ? true
                                      : false
                                  }
                                  onClick={() => {
                                    storeStepValues(
                                      step3,
                                      "question_thirteen",
                                      "",
                                      70,
                                      setStep3
                                    );
                                    smoothScroll("content5");
                                    setSelectedQuestion(4);
                                  }}
                                />
                                <span className="checkmark checkbox-input mt-1"></span>
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* right side */}
        <div
          className={`w-full lg:w-3/12 flex flex-col justify-between items-center lg:pb-6 ${formsRightQuestionsHeight()}`}
        >
          <div className="hidden lg:block">
            {/*active arrow */}
            {formOptions?.map((item, index) => (
              <div className="flex" key={item?.id}>
                <div>
                  <img
                    src={
                      item?.selected === "true" ? BlueArrow : TransparentArrow
                    }
                    className="h-8"
                    alt=""
                  />
                  {/* line between image */}
                  {item?.id !== 4 && (
                    <div className="flex justify-center">
                      <div
                        className={`w-[2px] h-6 
                         ${
                           item?.selected === "true" &&
                           formOptions[index + 1].selected === "true"
                             ? "bg-[#01a2da]"
                             : "bg-[#E7F6FD]"
                         }
                      `}
                      ></div>
                    </div>
                  )}
                </div>
                {
                  <div className="px-4 pt-1">
                    <p className="text-[#3A484D]">{item?.name}</p>
                  </div>
                }
              </div>
            ))}
          </div>
          {/* buttons */}
          <div
            className="flex flex-row lg:flex-col justify-between w-full lg:w-8/12 px-2"
            id="content5"
          >
            <div className="lg:w-12/12 lg:py-4">
              <button
                className="bg-white hover:bg-[#eee] border w-full rounded-full py-[10px] text-[#3A484D] text-[15px] px-10"
                onClick={() => {
                  setStep(2);
                  setShowAnimation("animate-right2");
                }}
              >
                {state?.language?.stepButton?.previous}
              </button>
            </div>
            <div className="lg:w-12/12">
              <button
                className="bg-[#01a2da] hover:bg-[#0590c1] w-full rounded-full py-[10px] text-white text-[15px] pr-10 pl-5"
                onClick={() => submitHandler()}
              >
                {state?.language?.stepButton?.next}
                <span className="absolute ml-[8px] lg:ml-[20px] mt-[6.5px]">
                  <img src={NextArrow} className="h-3" alt="" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step3;
